<template>
  <div class="sidebar">
    <div class="logo">
      <router-link to="/home"
        ><img
          src="../../assets/Images/logo.svg"
          alt="PixiMind"
          width="128"
          height="60"
          class="px-16"
      /></router-link>
    </div>
    <div v-for="row in rows" :key="row.id" class="px-16">
      <div
        class="ds-flex ds-center ds-justify-start pl-30"
        :class="{ 'active-element': activeId === row.id }"
        @click="changeActiveElement(row.id, row.link)"
      >
        <font-awesome-icon
          :icon="row.icon"
          class="pr-15"
          :style="[row.id === activeId ? { color: '#5B9200' } : { color: '' }]"
        />
        <Text
          v-if="row.id === activeId"
          className="active-color"
          :message="row.label"
        />
        <Text v-if="row.id !== activeId" :message="row.label" />
      </div>
    </div>
  </div>

  <div class="body-text">
    <slot />
  </div>
</template>

<script lang="ts">
import { ref, onMounted } from "vue";
import Text from "@/components/Atoms/AtomText.vue";
import { useAuthStore } from "@/Stores/auth";
import { useRouter } from "vue-router";

export default {
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const activeId = ref<number>(1);
    const rows = ref([
      {
        id: 1,
        label: "Dashboard",
        icon: "fa-solid fa-gauge",
        link: "/dashboard",
      },
      {
        id: 2,
        label: "Liste des abonnements",
        icon: "fa-solid fa-user",
        link: "/list-abonnement",
      },
      {
        id: 3,
        label: "Configuration",
        icon: "fa-solid fa-cog",
        link: "/config",
      },
      
      
    ]);

    const user = authStore.user;
    const thumbnailsPath = process.env.VUE_APP_THUMBNAILS_PATH;

    onMounted(() => {
      const storedActiveId = localStorage.getItem("activeId");
      if (storedActiveId) {
        activeId.value = parseInt(storedActiveId);
      }
    });

    const changeActiveElement = (row: number, link: string) => {
      activeId.value = row;
      router.push(link);
      localStorage.setItem("activeId", String(row));
    };

    return {
      activeId,
      rows,
      user,
      thumbnailsPath,
      changeActiveElement,
    };
  },
  components: {
    Text,
  },
};
</script>

<style scoped>
.sidebar {
  min-width: 212px;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  -moz-box-shadow: -3px 0 8px 0 #555;
  -webkit-box-shadow: -3px 0 8px 0 #555;
  box-shadow: -3px 0 8px 0 #555;
  cursor: pointer;
}

.non-active-element {
  display: block;
  height: 48px;
}

.active-element {
  padding-bottom: 5px;
  display: flex;
  border-radius: 8px;
  height: 48px;
  background: #eef8d6;
}

.active-color {
  color: #5b9200;
}

.user-info {
  display: flex;
  width: 174px;
  height: 60px;
  position: absolute;
  bottom: 0;
  padding-bottom: 15px;
  align-items: center;
}
</style>
