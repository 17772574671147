<template>
  <div
    :class="[
      'ds-flex',
      'container-navbar',
      'py-6',
      'px-16',
      'ds-align-center',
      className,
    ]"
  >
    <ul v-if="items" class="navbar-list">
      <li v-for="item in items" :key="item.text" class="txt btn-text">
        <router-link :to="item.link" class="navbar-item">{{
          item.text
        }}</router-link>
      </li>
    </ul>
    <ul class="ds-flex ds-align-center navbar-right">
      <font-awesome-icon
        icon="fa-regular fa-bell"
        :style="{ cursor: 'pointer' }"
        @click="handleBellIconClick"
      />
      <li>
        <Button text="Déconnexion" className="ml-20" @clicked="logout"></Button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MoleculNavbarItemsProp } from "@/Interfaces";
import Button from "@/components/Molecules/MoleculButton.vue";
import { useAuthStore } from "@/Stores/auth";

export default defineComponent({
  name: "MoleculNavbar",
  components: {
    Button,
  },
  props: {
    items: {
      type: Array as () => MoleculNavbarItemsProp[],
      required: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  setup() {
    const authStore = useAuthStore();

    const handleBellIconClick = () => {
      // Handle bell icon click logic here, e.g., show notifications or perform actions
    };

    const logout = () => {
      authStore.logout();
    };

    return {
      handleBellIconClick,
      logout,
    };
  },
});
</script>

<style scoped>
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  background-color: white;
  color: rgb(75, 81, 85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.container-navbar {
  height: 52px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
}

.navbar-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-right {
  list-style: none;
  position: absolute;
  right: 20px;
}

.navbar-item {
  display: block;
  padding: 20px;
  color: inherit;
  text-decoration: none;
}

.logo {
  cursor: pointer;
  list-style: none;
  height: 50px;
  position: absolute;
  left: 15px;
  top: 8px;
}
</style>
<style scoped>
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  background-color: white;
  color: rgb(75, 81, 85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.container-navbar {
  height: 52px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
}

.navbar-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-right {
  list-style: none;
  position: absolute;
  right: 20px;
}

.navbar-item {
  display: block;
  padding: 20px;
  color: inherit;
  text-decoration: none;
}

.logo {
  cursor: pointer;
  list-style: none;
  height: 50px;
  position: absolute;
  left: 15px;
  top: 8px;
}
</style>
