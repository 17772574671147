<template>
  <div class="check-box-group">
    <label class="custom-checkbox" tabindex="0" aria-label="Checkbox Label">
      <input type="checkbox" v-model="checked" @change="handleChange" />
      <span class="checkmark"></span>
      <span :class="labelClassNames">{{ label }}</span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";

export default defineComponent({
  name: "AtomCheckbox",
  props: {
    label: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props :any, { emit }:any) {
    const checked = ref(props.isChecked);

    const labelClassNames = computed(() => {
      const classNames = ["label"];
      if (checked.value) {
        classNames.push("colored");
      }
      return classNames.join(" ");
    });

    function handleChange(event: Event) {
      const target = event.target as HTMLInputElement | null;
      if (target) {
        checked.value = target.checked;
        emit("handleChange", checked.value);
      }
    }

    watch(
      () => props.isChecked,
      (newValue) => {
        checked.value = newValue;
      }
    );

    return {
      checked,
      labelClassNames,
      handleChange,
    };
  },
});
</script>

<style scoped>
.check-box-group {
  display: flex;
  flex-direction: column;
}

.custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 18px;
  width: max-content;
}

.custom-checkbox .label {
  width: max-content;
  height: 24px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
  user-select: none;
  color: #1d324a;
}

.custom-checkbox .colored {
  color: #71c200;
}

.custom-checkbox .checkmark {
  width: 16px;
  height: 16px;
  border: 1px solid #1d324a;
  border-radius: 2px;
  display: inline-block;
  background: #71c200
    url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png)
    center/1250% no-repeat;
  transition: background-size 0.2s ease;
}

.custom-checkbox input:checked + .checkmark {
  background-size: 60%;
  transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
}

.custom-checkbox input {
  display: none;
}
</style>
