<template>
  <div class="home">
    <div style="margin-bottom:10px;"><Button type="type1" className="" text="Button" @click="agree"></Button></div>
    <div>
      <MoleculButton type="" text="Button" iconLeft disabled></MoleculButton>
    </div>
    <div>
      <Text className="" type="subtitle-"></Text>
    </div>
    <div style="margin-top:10px;">

    </div>
    <Label :state="State.rejected" message="Rejected"></Label>
    <Label :state="State.completed" message="Completed" withBackground></Label>
    
  </div>
  <div class="ds-wp-375">
    <Input :state="InputState.success" />
    <MoleculInput labelText="Email" :inputText="inputEmail.msg" :state="inputEmail.etat" placeholder="Email"
      @handleInput="emailRules" isRequired/>
    <MoleculInput labelText="Password" :inputText="msg" :state="etat" placeholder="Password" @handleInput="passwordRules"
      @focusout="mouseLeave" isPassword/>
    <Button type="type1" className="" text="Button" :disabled="btnDisabled"></Button>
    <Checkbox label="Se souvenir de moi" id="test" />
    <Select></Select>
  </div>
  <!-- <Navbar :items="[{ text: 'Home', link: '/' }, { text: 'About', link: '/about' }]"></Navbar> -->
  <Avatar v-if="user.avatar !== ''" :src="require('../assets/Images/' + user.avatar)" :alt="user.name" :title="user.name"
    :size="Size.xlarge"></Avatar>
  <Avatar v-if="user.avatar === ''" :alt="user.name" :title="user.name" :size="Size.xlarge" :fullName="textImage">
  </Avatar>
  <div class="page-container">
    <Table :columns="columns" :data="items" :selectable="true">
      <template v-slot:status="{ data }">
        <Label :state="State.completed" message="Completed"></Label>
        {{ data }}
      </template>
      <template v-slot:actions="{ data }">
        <div class="actions">
          <font-awesome-icon icon="fa-solid fa-download" @click="editItem(data)" />
          <font-awesome-icon icon="fa-regular fa-pen-to-square" @click="deleteItem(data)" />
          <font-awesome-icon icon="fa-regular fa-trash-can" @click="deleteItem(data)" />
        </div>
      </template>
    </Table>
    <Teleport to="#modal">
      <ModalConfirm v-if="isVisible" @close="closeConfirm"></ModalConfirm>
    </Teleport>
    <button @click="openConfirm">Open Confirm Modal</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Button from '@/components/Atoms/AtomButton.vue';
import Text from '@/components/Atoms/AtomText.vue';
import Label from '@/components/Atoms/AtomLabel.vue';
import Input from '@/components/Atoms/AtomInput.vue';
import Checkbox from '@/components/Atoms/AtomCheckBox.vue';
import Select from '@/components/Atoms/AtomSelect.vue';
import Avatar from '@/components/Atoms/AtomAvatar.vue';
import MoleculButton from '@/components/Molecules/MoleculButton.vue';
import MoleculInput from '@/components/Molecules/MoleculInput.vue';
/* import Navbar from '@/components/Molecules/MoleculNavbar.vue'; */
import Table from '@/components/Organisms/OrganismTable.vue';
import ModalConfirm from '@/components/Organisms/modalConfirm.vue'
import { State, InputState, Size } from '@/Interfaces';
import { pwRules, emailRules } from '@/utils/validation'


export default defineComponent({
  name: 'HomeView',
  components: {
    Button,
    MoleculButton,
    Text,
    Label,
    Input,
    Checkbox,
    MoleculInput,
    Select,
    /* Navbar, */
    Avatar,
    Table,
    ModalConfirm
  },
  data: () => ({
    isAgree: false,
    test: "test",
    val: State.InProgress,
    msg: '',
    taille: 0,
    etat: InputState.default,
    State,
    InputState,
    Size,
    inputEmail :{
      etat: InputState.default,
      msg: '',
      value: ''
    },
    filled: {
      "false": 0,
      "true": 0
    },
    user: {
      name: 'test test',
      avatar: 'avatar.png'
    },
    columns: [
      { label: "ID", field: "id" },
      { label: "Name", field: "name" },
      { label: "Email", field: "email" },
      { label: "Status", field: "status" },
      { label: "Actions", field: "actions", sortable: false },
      
    ],
    items: [
      { id: 1, name: "John Doe", email: "john@example.com", status:"rejected" },
      { id: 2, name: "Jane Doe", email: "jane@example.com", status:"InProgress"},
      { id: 3, name: "Bob Smith", email: "bob@example.com", status:"completed" },
    ],
    status : [] ,
    time: Object,
    lista:[{"id":1,"texto":"ID"},{"id":2,"texto":"Name"},{"id":3,"texto":"Email"},{"id":4,"texto":"Status"},
          {"id":5,"texto":"actions"}],
    active_el:0,
    old_active_el:0,
    isVisible : false
  }),
  methods: {
    agree() {
      this.isAgree = !this.isAgree
    },
    onClickUsername(value: string) {
      console.log(value)
    },
    emailRules(value: string){
      const obj = emailRules(value)
      console.log(value);
      
      this.inputEmail.etat = obj.userEtat
      this.inputEmail.msg = obj.userMsg
      this.inputEmail.value = value
    },
    passwordRules(value: string) {
      const obj = pwRules(value)
      this.msg = obj.msgInput 
      this.etat = obj.stateInput 
    },
    mouseLeave(event: any) {
      if (event.target.value.length === 0) {
        this.etat = InputState.error
        this.msg = "Le mot de passe est obligatoire!"
      }
    },
    editItem(item: any) {
      console.log("Edit item:", item);
    },
    deleteItem(item: any) {
      console.log("Delete item:", item);
    },
    changeStatusToType(){
      const itemsChanged = this.items.map(x => {
        if(x.status==='rejected'){
          x.status = State.rejected
        }
        else if(x.status==='completed'){
          x.status = State.completed
        }
        else{
          x.status = State.InProgress
        }
      })
      console.log(itemsChanged)
      return itemsChanged
    },
    activate:function(el: any) : any{
      if(this.active_el === el){
        this.old_active_el=this.active_el
        this.active_el = -1;
        console.log(this.lista[this.old_active_el-1].texto+"descendent")

      }
        else
      {
        this.active_el = el;
        console.log(this.lista[this.active_el-1].texto+"ascendent")

      }

    },
    openConfirm(){
      this.isVisible = true
    },
    closeConfirm(){
      this.isVisible = false
    }
  },
  created(){
    this.changeStatusToType();
  },
  computed: {
    btnDisabled() {

      if( emailRules(this.inputEmail.value).userEtat === InputState.success ) 
      {return false}
      return true
    },
    textImage() {
      const fullName = this.user.name.split(" ")
      const firstName = fullName[0].charAt(0).toUpperCase()
      const lastName = fullName[1] !== undefined ? fullName[1].charAt(0).toUpperCase() : ''
      return firstName + '' + lastName
    }
  },
  watch: {

  }
});
</script>
<style scoped>
.test {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #66C0BC;
}

.page-container {
  max-width: 800px;
  margin: 0 auto;
}

.actions {
  display: flex;
  justify-content: space-evenly;
}

.on-hover:hover {
  width: 24px;
  height: 24px;
  background: #20f120;
  border-radius: 8px;
}

ul > li:hover {
  cursor:pointer;
}
.active  {
  content: url("../assets/Images/flesh-up.svg");
  width: 90px;
  height: 90px;

}
.inactive {
     content: url("../assets/Images/flesh-up-down.svg");
     width: 90px;
     height: 90px;
}

.seconde {
     content: url("../assets/Images/flesh-down.svg");
     width: 90px;
     height: 90px;
}

</style>
