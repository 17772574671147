import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6429eb39"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["type", "placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.generalClassNames)
  }, [
    (_ctx.isPassword)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hidePassword && _ctx.hidePassword(...args))),
          class: "hide-password"
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: _ctx.showIconPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash',
            style: { color: '#8E98A4' }
          }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: _ctx.computedInputType,
      placeholder: _ctx.placeholder,
      class: _normalizeClass(_ctx.classNames),
      value: _ctx.value,
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      disabled: _ctx.disabled
    }, null, 42, _hoisted_1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}