<template>
  <div v-if="isAuthenticated" class="flex">
    <Sidebar> </Sidebar>
    <div class="page-content">
      <Navbar v-if="isAuthenticated"></Navbar>
      <router-view></router-view>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script lang="ts">
import { useAuthStore } from "./Stores/auth";
import Sidebar from "@/components/Organisms/OrganismSidebar.vue";
import Navbar from "@/components/Molecules/MoleculNavbar.vue";
import { computed } from "vue";

export default {
  name: "App",
  components: {
    Sidebar,
    Navbar,
  },
  setup() {
    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);

    const logout = () => {
      authStore.logout();
    };

    return {
      isAuthenticated,
      logout,
    };
  },
};
</script>

<style lang="scss">
.right {
  position: fixed;
}
.flex {
  height: 100vh;
  width: 100vw;
  display: flex;
}
.page-content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
}
</style>
