import { RouteRecordRaw } from "vue-router";

const plansRoute: RouteRecordRaw = {
  path: "/plans",
  name: "plans",
  component: () => import("../views/Plans/PlansComponent.vue"),
  meta: {
    requiresAuth: true,
  },
};

export default plansRoute;
