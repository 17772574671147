<template>
  <div :class="computedClassLabel">
    <span :class="computedClassSpan">{{ message }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { State } from "@/Interfaces";

export default defineComponent({
  name: "AtomLabel",
  props: {
    message: {
      type: String,
      default: "In Progress",
    },
    type: {
      type: String,
      default: "",
    },
    state: {
      type: String as PropType<State>,
      default: State.InProgress,
    },
    withBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const computedClassLabel = computed(() => {
      const classLabel = ["ellipse"];
      if (props.state) {
        classLabel.push(props.state + "icon");
      }
      if (props.withBackground) {
        classLabel.splice(0, classLabel.length);
        classLabel.push(props.state + "cadre");
        classLabel.push(props.state);
      }
      return classLabel.join(" ");
    });

    const computedClassSpan = computed(() => {
      const classSpan = ["txt-1"];
      if (props.state) {
        classSpan.push(props.state);
      }
      if (props.withBackground) {
        classSpan.splice(0, classSpan.length);
        classSpan.push("txtcadre");
        classSpan.push(props.state);
      }
      return classSpan.join(" ");
    });

    return {
      computedClassLabel,
      computedClassSpan,
    };
  },
});
</script>

<style scoped>
.default {
  color: black;
}

.txt-1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  flex: none;
  flex-grow: 0;
  padding-left: 11px;
}

.ellipse {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.rectif {
  padding-top: 6px;
}
</style>
