import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxAtom = _resolveComponent("CheckboxAtom")!
  const _component_ColumnTable = _resolveComponent("ColumnTable")!

  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("th", null, [
          ($props.selectable)
            ? (_openBlock(), _createBlock(_component_CheckboxAtom, {
                key: 0,
                id: "b",
                onHandleChange: $setup.toggleAllSelected,
                isChecked: $setup.allSelected,
                style: {"padding-left":"8px"}
              }, null, 8, ["onHandleChange", "isChecked"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ColumnTable, {
          columns: $props.columns,
          onSortTable: $setup.sortTable
        }, null, 8, ["columns", "onSortTable"])
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, index) => {
        return (_openBlock(), _createElementBlock("tr", { key: index }, [
          _createElementVNode("td", null, [
            ($props.selectable)
              ? (_openBlock(), _createBlock(_component_CheckboxAtom, {
                  key: 0,
                  id: 'i' + item.id,
                  onHandleChange: ($event: any) => ($setup.toggleSelected(item)),
                  isChecked: $setup.selectedItems.includes(item)
                }, null, 8, ["id", "onHandleChange", "isChecked"]))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column) => {
            return (_openBlock(), _createElementBlock("td", {
              key: column.field
            }, [
              _renderSlot(_ctx.$slots, column.field, {
                item: item,
                index: index
              }, () => [
                _createTextVNode(_toDisplayString(item[column.field]), 1)
              ], true)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ]))
}