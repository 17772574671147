import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9e37fa70"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.iconLeft)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass(_ctx.classNames),
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: _normalizeClass(_ctx.classIcons),
          icon: _ctx.icon,
          spin: _ctx.isLoading
        }, null, 8, ["class", "icon", "spin"]),
        _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
      ], 10, _hoisted_1))
    : (_ctx.iconRight)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: _normalizeClass(_ctx.classNamesReverse),
          disabled: _ctx.disabled,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: _normalizeClass(_ctx.classIcons),
            icon: _ctx.icon,
            spin: _ctx.isLoading
          }, null, 8, ["class", "icon", "spin"]),
          _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
        ], 10, _hoisted_2))
      : (_ctx.iconOnly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: _normalizeClass(_ctx.classNames),
            disabled: _ctx.disabled,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: _normalizeClass(_ctx.classIcons),
              icon: _ctx.icon,
              spin: _ctx.isLoading
            }, null, 8, ["class", "icon", "spin"])
          ], 10, _hoisted_3))
        : (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: _normalizeClass(_ctx.classNames),
            disabled: _ctx.disabled,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
          }, _toDisplayString(_ctx.text), 11, _hoisted_4))
}