import { defineStore } from "pinia";
import router from "../router/index";
import { User } from "@/Interfaces/IUser";
import "vue3-toastify/dist/index.css";

interface AuthState {
  token: string | null;
  user: User | null;
  permissions: string[] | null;
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    token: localStorage.getItem("token") || null,
    user: null,
    permissions: null,
  }),
  getters: {
    user: (state) => state.user,
    isAuthenticated: (state) => state.token !== null,
  },
  actions: {
    authUser(userData: { token: string; user: User; permissions: string[] }) {
      this.$patch({
        token: userData.token,
        user: userData.user,
        permissions: userData.permissions,
      });
    },
    clearAuthData() {
      this.$patch({
        user: null,
        token: null,
        permissions: null,
      });
    },
    logout() {
      this.clearAuthData();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("access_token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("activeId");
      router.replace("/login");
    },
  },
});
