<template>
  <div :class="generalClassNames">
    <span v-if="isPassword" @click="hidePassword" class="hide-password">
      <font-awesome-icon
        :icon="showIconPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
        :style="{ color: '#8E98A4' }"
      />
    </span>
    <input
      :type="computedInputType"
      :placeholder="placeholder"
      :class="classNames"
      :value="value"
      @input="handleInput"
      :disabled="disabled"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from "vue";
import { InputState, ETypesInput } from "@/Interfaces";

export default defineComponent({
  name: "AtomInput",
  emits: ["handleInput"],
  props: {
    text: {
      type: String,
      default: "Default",
    },
    placeholder: {
      type: String,
      default: "placeholder",
    },
    className: {
      type: String,
    },
    generalClassName: {
      type: String,
    },
    type: {
      type: String,
      default: "",
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String as PropType<InputState>,
      default: InputState.default,
    },
    size: {
      type: String,
      default: "large",
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props:any, { emit }:any) {
    const val = ref("");
    const showIconPassword = ref(false);

    const classNames = computed(() => {
      const classes = ["input-ds", InputState.default];
      if (props.state !== InputState.default) {
        classes.pop();
        classes.push(props.state);
      }
      if (props.type) {
        classes.push(props.type);
      }
      if (props.className) {
        classes.push(props.className);
      }
      return classes.join(" ");
    });

    const generalClassNames = computed(() => {
      const classes = ["ds-flex", "ds-align-center", "relative"];
      if (props.generalClassName) {
        classes.push(props.generalClassName);
      }
      return classes.join(" ");
    });

    const computedInputType = computed(() => {
      return props.isPassword && !showIconPassword.value
        ? ETypesInput.password
        : ETypesInput.text;
    });

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      val.value = target.value;
      emit("handleInput", target.value);
    };

    const hidePassword = () => {
      showIconPassword.value = !showIconPassword.value;
    };

    return {
      val,
      showIconPassword,
      classNames,
      generalClassNames,
      computedInputType,
      handleInput,
      hidePassword,
    };
  },
});
</script>

<style scoped>
.input-ds {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 4px;
  width: 100%;
  height: 40px;
  outline: none;
  border-radius: 8px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #8e98a4;
}
.input-ds.default {
  border: 1px solid #c9ced4;
}

.input-ds.success {
  border: 1px solid #16a34a;
}

.input-ds.error {
  border: 1px solid #fca5a5;
}
.hide-password {
  position: absolute;
  right: 15px;
}
</style>
