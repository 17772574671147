import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.computedClassLabel)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.computedClassSpan)
    }, _toDisplayString(_ctx.message), 3)
  ], 2))
}