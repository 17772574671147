<template>
  <div
    class="page-container box"
    style="
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 15px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    "
  >
    <div>
      <h3>Bienvenue chez coodapp</h3>
    </div>
    <div v-if="isLoading" class="loader-container">
      <!-- Indicateur de chargement, par exemple un spinner -->
      <div class="spinner"></div>
      <p>Chargement...</p>
    </div>
    <div v-else>
      <div class="row">
        <MoleculInput
          labelText="Nom"
          placeholder="Nom"
          :inputText="fields.nom.message.value"
          :state="fields.nom.etat.value"
          @focusout="(event) => mouseLeave(event, 'nom', 'nom')"
          @handleInput="(value) => handleInput('nom', value)"
          :value="fields.nom.value.value"
          :isRequired="true"
        />
        <MoleculInput
          labelText="Prénom"
          placeholder="Prénom"
          :inputText="fields.prenom.message.value"
          :state="fields.prenom.etat.value"
          @focusout="(event) => mouseLeave(event, 'prenom', 'prénom')"
          @handleInput="(value) => handleInput('prenom', value)"
          :value="fields.prenom.value.value"
          :isRequired="true"
        />

        <MoleculInput
          labelText="Email"
          :inputText="inputEmail.msg"
          :state="inputEmail.etat"
          placeholder="nom.prenom@exemple.com"
          :value="inputEmail.value"
          @handleInput="validateEmailRules"
          :isRequired="true"
        />

        <Select
          labelText="Pays"
          :items="transformedCountries"
          v-model="selectedPays"
          itemLabel="name"
          itemValue="name"
          @change="handleSelectPays"
          :isRequired="true"
        ></Select>

        <Text class="txt btn-text mb-8 type-2" message="Téléphone"></Text>
        <MazPhoneNumberInput
          v-model="phoneNumber"
          :exclude-selectors="['#input-flag-element']"
          :translations="{
            countrySelector: {
              placeholder: 'Pays',
              error: 'Choisir pays',
              searchPlaceholder: 'Recherche pays',
            },
            phoneInput: {
              placeholder: 'Num téléphone',
              example: 'Exemple:',
            },
          }"
        >
          <template #selector-flag="{ countryCode }">
            <span
              id="input-flag-element"
              style="
                font-size: 0.8rem;
                background-color: var(--maz-color-secondary);
                color: var(--maz-color-secondary-contrast);
                border-radius: 100px;
                padding: 2px;
              "
            >
              {{ countryCode }}
            </span>
          </template>
          <template #country-list-flag="{ countryCode }">
            <MazBadge size="0.8rem" style="margin-right: 10px; width: 26px">
              {{ countryCode }}
            </MazBadge>
          </template>
        </MazPhoneNumberInput>

        <!-- <div class="input-group">
          <MoleculInput
            labelText="Téléphone"
            placeholder="Téléphone"
            :inputText="fields.telephone.message.value"
            :state="fields.telephone.etat.value"
            @handleInput="telephoneRules"
            :value="fields.telephone.value.value"
            :isRequired="true"
          />
        </div> -->

        <!-- <MoleculInput
        labelText="Pays"
        placeholder="Pays"
        :inputText="fields.pays.message.value"
        :state="fields.pays.etat.value"
        @handleInput="(value) => handleInput('pays', value)"
        @focusout="(event) => mouseLeave(event, 'pays','pays')"
        :value="fields.pays.value.value"
        :isRequired="true"
      /> -->

        <MoleculInput
          labelText="Adresse"
          placeholder="Adresse"
          :inputText="fields.adresse.message.value"
          :state="fields.adresse.etat.value"
          @handleInput="(value) => handleInput('adresse', value)"
          @focusout="(event) => mouseLeave(event, 'adresse', 'adresse')"
          :value="fields.adresse.value.value"
          :isRequired="true"
        />

        <MoleculInput
          labelText="Nom du restaurant"
          placeholder="Nom du restaurant"
          :inputText="fields.boutique.message.value"
          :state="fields.boutique.etat.value"
          @focusout="
            (event) => mouseLeave(event, 'boutique', 'nom du restaurant')
          "
          @handleInput="(value) => handleInput('boutique', value)"
          :value="fields.boutique.value.value"
          :isRequired="true"
        />
        <MoleculInput
          labelText="Sous domaine"
          placeholder="Sous domaine"
          :inputText="fields.domaine.message.value"
          :state="fields.domaine.etat.value"
          @focusout="(event) => mouseLeave(event, 'domaine', 'sous domaine')"
          @handleInput="(value) => handleInput('domaine', value)"
          :value="fields.domaine.value.value"
          :isRequired="true"
        />

        <Select
          labelText="Type de contrat"
          :items="selectItems"
          v-model="selectedItem"
          @handleSelect="handleSelect"
          @change="handleChange"
          :isRequired="true"
        ></Select>
        <div style="margin-top: 10px">
          <AtomRadio
            v-if="contrat == 'demo'"
            name="content"
            value="with"
            :selected="selected"
            label="Avec contenu"
            @handleRadio="handleRadio"
          />
          <AtomRadio
            v-if="contrat == 'demo'"
            name="content"
            value="without"
            :selected="selected"
            label="Sans contenu"
            @handleRadio="handleRadio"
          />
        </div>

        <MoleculInput
          labelText="Identifiant"
          placeholder="Identifiant"
          :inputText="fields.identifiant.message.value"
          :state="fields.identifiant.etat.value"
          @handleInput="(value) => handleInput('identifiant', value)"
          @focusout="(event) => mouseLeave(event, 'identifiant', 'identifiant')"
          :value="fields.identifiant.value.value"
          :isRequired="true"
        />
        <MoleculInput
          labelText="Mot de passe"
          :inputText="fields.password.message.value"
          :state="fields.password.etat.value"
          placeholder="Mot de passe"
          :value="fields.password.value.value"
          @handleInput="passwordRules"
          isPassword
          :isRequired="true"
        />
        <MoleculInput
          labelText="Confirmer le mot de passe"
          :inputText="fields.confirmPassword.message.value"
          :state="fields.confirmPassword.etat.value"
          placeholder="Répeter le mot de passe"
          :value="fields.confirmPassword.value.value"
          @handleInput="confirmPasswordRules"
          isPassword
          :isRequired="true"
        />
      </div>

      <div style="margin-bottom: 10px; margin-top: 10px">
        <Button
          type="type1"
          class=""
          text="Valider"
          @click="submitForm"
        ></Button>
      </div>
    </div>
  </div>
  <div style="margin-top: 10px"></div>
</template>

<script lang="ts">
import countries from "@/data/countries.json";
import { ref, computed, Ref, onMounted } from "vue";
import MoleculInput from "@/components/Molecules/MoleculInput.vue";
import Button from "@/components/Atoms/AtomButton.vue";
import AtomRadio from "@/components/Atoms/AtomRadio.vue";
import Text from "@/components/Atoms/AtomText.vue";
import Select from "@/components/Atoms/AtomSelect.vue";
import { InputState } from "@/Interfaces";
import { pwRules, emailRules, confirmPwRules } from "@/utils/validation";
import { useToastStore } from "@/Stores/toast";

interface FieldData {
  etat: Ref<InputState>;
  message: Ref<string>;
  value: Ref<string>;
}

interface Country {
  order: number;
  code: string;
  name: string;
}

export default {
  components: {
    MoleculInput,
    Button,
    AtomRadio,
    Select,
    Text,
  },
  setup(props: any, { emit }: any) {
    const toastStore = useToastStore();
    const msg = ref("");
    const isLoading = ref(true);

    const fields: { [key: string]: FieldData } = {
      nom: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      prenom: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      email: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      pays: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },

      adresse: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      boutique: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      domaine: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      identifiant: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      password: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
      confirmPassword: {
        etat: ref(InputState.default),
        message: ref(""),
        value: ref(""),
      },
    };

    onMounted(() => {
      isLoading.value = false;
    });
    const contrat = ref("");
    const handleInput = (field: string, value: string) => {
      fields[field].value.value = value;
    };

    const inputEmail = ref({ etat: InputState.default, msg: "", value: "" });
    const mouseLeave = (event: any, champ: string, message: string) => {
      const value = event.target.value;

      if (event.target.value.length === 0) {
        fields[champ].etat.value = InputState.error;
        fields[champ].message.value = `Le ${message} est obligatoire!`;
      } else {
        fields[champ].etat.value = InputState.success;
        fields[champ].message.value = "";
        fields[champ].value.value = value;
      }
    };
    const handleChange = (event: any) => {
      selectedItem.value = event.target.value;
    };
    const handleSelect = (selectedItem: any) => {
      contrat.value = selectedItem;
      emit("handleSelect", selectedItem);
    };

    const handleRadio = (selectedValue: any) => {
      selected.value = selectedValue;
    };

    const passwordRules = (value: any) => {
      const obj = pwRules(value);
      fields.password.etat.value = obj.stateInput;
      fields.password.message.value = obj.msgInput;
      fields.password.value.value = value;
    };

    const confirmPasswordRules = (value: string) => {
      const obj = confirmPwRules(value, fields.password.value.value);
      fields.confirmPassword.etat.value = obj.stateInput;
      fields.confirmPassword.message.value = obj.msgInput;
      fields.confirmPassword.value.value = value;
    };

    const btnDisabled = computed(() => {
      if (inputEmail.value.etat === InputState.success) {
        return false;
      }
      return true;
    });

    const validateEmailRules = (value: string) => {
      const obj = emailRules(value);
      inputEmail.value.etat = obj.userEtat;
      inputEmail.value.msg = obj.userMsg;
      inputEmail.value.value = value;
    };

    const selectedItem = ref(null);
    const selectedPays = ref();
    const selected = ref("without");

    const selectItems = [
      { label: "Pro", value: "pro" },
      { label: "Demo", value: "demo" },
    ];
    const selectPays: Ref<Country[]> = ref([]);
    onMounted(() => {
      selectPays.value = countries;
    });

    // Transform the countries data to match the expected format
    const transformedCountries = ref(
      countries.map((country: any) => ({
        label: country.name,
        value: country.order,
      }))
    );

    const handleSelectPays = (event: any) => {
      const selectedValue = event.target.value;
      console.log("selectedValue: ", selectedValue);

      const country = countries.find(
        (country) => country.order == selectedValue
      );
      if (country) {
        selectedPays.value = country.name;
      }else{
        selectedPays.value = '';
      }
    };

    const validateField = (fieldName: string) => {
      const field = fields[fieldName];
      if (field.value.value.length === 0) {
        field.etat.value = InputState.error;
        field.message.value = `Le ${fieldName} est obligatoire!`;
      }
    };
    const submitForm = async () => {
      isLoading.value = true;

      try {
        const formData = {
          nom: fields.nom.value.value,
          prenom: fields.prenom.value.value,
          telephone: phoneNumber.value,
          adresse: fields.adresse.value.value,
          pays: selectedPays.value,
          sous_domaine: fields.domaine.value.value,
          boutique: fields.boutique.value.value,
          email: inputEmail.value.value,
          contrat: contrat.value,
          contenu: selected.value,
          identifiant: fields.identifiant.value.value,
          password: fields.password.value.value,
        };
        const response = await fetch(
          `${process.env.VUE_APP_ROOT_API}/submit-form`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const responseData = await response.json();
        if (response.status === 200) {
          const submittedId = responseData.id;

          toastStore.renderToast({
            toastType: "success",
            toastMessage: responseData.message,
          });

          const requestData = {
            idDemande: submittedId,
            formData: formData,
          };
          // Appeler une autre API si le statut est 200
          const instanceResponse = await fetch(
            `${process.env.VUE_APP_ROOT_API}/create-instance`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestData),
            }
          );

          const instanceData = await instanceResponse.json();
          if (response.status === 200) {
            toastStore.renderToast({
              toastType: "success",
              toastMessage: instanceData.message,
            });
            isLoading.value = false;
            setTimeout(() => {
              window.location.href = "https://www.clickfood.cood.app/";
            }, 3000);
          } else {
            toastStore.renderToast({
              toastType: "error",
              toastMessage: instanceData.error,
            });
            isLoading.value = false;
          }
        } else {
          toastStore.renderToast({
            toastType: "error",
            toastMessage: responseData.error,
          });
          isLoading.value = false;
        }
      } catch (error) {
        toastStore.renderToast({
          toastType: "error",
          toastMessage: "Erreur lors de la soumission du formulaire !",
        });
        console.error("Erreur lors de la soumission du formulaire :", error);
      }
    };

    const phoneNumber = ref();
    const countryCode = ref("TN");
    const results = ref();

    return {
      contrat,
      msg,
      inputEmail,
      validateEmailRules,
      fields,
      selectedItem,
      selectedPays,
      selected,
      selectItems,
      btnDisabled,
      emailRules,
      validateField,
      submitForm,
      handleInput,
      mouseLeave,
      handleRadio,
      handleSelect,
      passwordRules,
      confirmPasswordRules,
      handleChange,
      isLoading,
      selectPays,
      handleSelectPays,
      transformedCountries,
      phoneNumber,
      countryCode,
      results,
    };
  },
};
</script>

<style scoped>
.calendar {
  width: 375px;
  height: 40px;
  background-color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #8e98a4;
}
div .dp--tp-wrap {
  display: none !important;
}
::v-deep(.dp__theme_light) {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #79c300;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #79c300;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: rgba(25, 118, 210, 0.1);
}
::v-deep(.dp__input) {
  border-radius: 8px;
}
.test {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #66c0bc;
}

.page-container {
  max-width: 800px;
  margin: 0 auto;
}

.actions {
  display: flex;
  justify-content: space-evenly;
}

.on-hover:hover {
  width: 24px;
  height: 24px;
  background: #20f120;
  border-radius: 8px;
}

ul > li:hover {
  cursor: pointer;
}
.active {
  content: url("../assets/Images/flesh-up.svg");
  width: 90px;
  height: 90px;
}
.inactive {
  content: url("../assets/Images/flesh-up-down.svg");
  width: 90px;
  height: 90px;
}

.seconde {
  content: url("../assets/Images/flesh-down.svg");
  width: 90px;
  height: 90px;
}

.m-phone-number-input {
  width: 100%;
  /* border:1px solid #c9ced4;
  border-radius: 11px; */
}
::v-deep .maz-rounded {
  border-radius: 8px;
}
::v-deep .m-input-wrapper.--default-border[data-v-84073434] {
  border-color: #c9ced4;
  border-radius: 8px;
}
::v-deep
  .m-phone-number-input.--responsive
  .m-phone-input.--border-radius[data-v-34e13002]
  .m-input-wrapper {
  border-top-right-radius: 8px;
}
::v-deep
  .m-phone-number-input.--responsive
  .m-country-selector__select[data-v-b24f2449]
  .m-select-input
  .m-input-wrapper {
  border-bottom-left-radius: 8px;
}
::v-deep .radio-container .checkmark[data-v-a77f7806][data-v-a77f7806]:after {
    top: 3px;
    left: 2px;
}
::v-deep input,optgroup,select,textarea {
  font-family: "Lato";
}
::v-deep .maz-truncate {
  font-family: "Lato";
}
</style>
