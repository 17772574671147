<template>
  <button
    v-if="iconLeft"
    :class="classNames"
    :disabled="disabled"
    @click="handleClick"
  >
    <font-awesome-icon :class="classIcons" :icon="icon" :spin="isLoading" />
    {{ text }}
  </button>
  <button
    v-else-if="iconRight"
    :class="classNamesReverse"
    :disabled="disabled"
    @click="handleClick"
  >
    <font-awesome-icon :class="classIcons" :icon="icon" :spin="isLoading" />
    {{ text }}
  </button>
  <button
    v-else-if="iconOnly"
    :class="classNames"
    :disabled="disabled"
    @click="handleClick"
  >
    <font-awesome-icon :class="classIcons" :icon="icon" :spin="isLoading" />
  </button>
  <button v-else :class="classNames" :disabled="disabled" @click="handleClick">
    {{ text }}
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "MoleculButton",
  props: {
    text: {
      type: String,
      default: "Default",
    },
    className: {
      type: String,
    },
    type: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "fa-solid fa-arrow-right",
    },
    iconLeft: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const classNames = computed(() => {
      const classNames = ["btn"];
      if (props.type) {
        classNames.push(props.type);
      }
      if (props.className) {
        classNames.push(props.className);
      }
      return classNames.join(" ");
    });

    const classNamesReverse = computed(() => {
      const classNames = ["btn", "flex-row-reverse"];
      if (props.type) {
        classNames.push(props.type);
      }
      if (props.className) {
        classNames.push(props.className);
      }
      return classNames.join(" ");
    });

    const classIcons = computed(() => {
      const classIcons = [];
      if (props.iconLeft) {
        classIcons.push("mr-12");
      } else if (props.iconRight) {
        classIcons.push("ml-12");
      }
      return classIcons.join(" ");
    });

    const handleClick = () => {
      emit("clicked");
    };

    return {
      classNames,
      classNamesReverse,
      classIcons,
      handleClick,
    };
  },
});
</script>

<font-awesome-icon icon="fa-solid fa-arrow-right" />

<style scoped>
.btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  background: #79c300;
  border-radius: 4px;
  order: 0;
  border: none;
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-content: center;
  padding: 11px;
  cursor: pointer;
  height: 40px;
  border: 3px solid #79c300;
}
.left {
  padding-right: 12px;
}
.right {
  padding-left: 12px;
}

.btn:hover {
  background: #a5dc31;
  border: 3px solid #a5dc31;
}

.btn:active {
  background: #66a300;
  border: 3px solid #66a300;
}

.btn:disabled {
  background: #dfe2e6;
  border-color: #dfe2e6;
  cursor: default;
}
/*btn:focus{
    border-color: #A5DC31;  
    background-color:#66A300 ;
}*/

.btn.type1 {
  background-color: #ffffff;
  color: #79c300;
}

.btn.type1:hover {
  border-color: #a5dc31;
  color: #a5dc31;
}

.btn.type1:active {
  border-color: #66a300;
  color: #66a300;
}
.btn.type1:disabled {
  border-color: #dfe2e6;
  color: #dfe2e6;
  cursor: default;
}

.btn.type2 {
  background-color: #ffffff;
  color: #79c300;
  border: none;
}

.btn.type2:hover {
  color: #a5dc31;
}

.btn.type2:active {
  color: #66a300;
}
.btn.type2:disabled {
  color: #dfe2e6;
  cursor: default;
}
</style>
