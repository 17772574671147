<template>
  <table>
    <thead>
      <tr>
        <th>
          <CheckboxAtom
            id="b"
            v-if="selectable"
            @handleChange="toggleAllSelected"
            :isChecked="allSelected"
            style="padding-left: 8px"
          />
        </th>
        <ColumnTable :columns="columns" @sortTable="sortTable"></ColumnTable>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td>
          <CheckboxAtom
            :id="'i' + item.id"
            v-if="selectable"
            @handleChange="toggleSelected(item)"
            :isChecked="selectedItems.includes(item)"
          />
        </td>
        <td v-for="column in columns" :key="column.field">
          <slot :name="column.field" :item="item" :index="index">
            {{ item[column.field] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { ref, computed, watch, PropType } from "vue";
import { State } from "@/Interfaces";

interface TableColumn {
  field: string;
  label: string;
  slot?: string;
}

interface TableDataItem {
  id: string | number;
  [key: string]: any;
}

export default {
  props: {
    columns: {
      type: Array as () => TableColumn[],
      required: true,
    },
    data: {
      type: Array as () => TableDataItem[],
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String as PropType<State>,
      default: State.InProgress,
    },
  },
  setup(
    props: any,
    { emit }: any
  ) {
    const selectedItems = ref<TableDataItem[]>([]);

    const allSelected = computed(() => {
      return (
        props.data.length > 0 &&
        selectedItems.value.length === props.data.length
      );
    });

    const displayedData = computed(() => {
      return props.data;
    });

    watch(displayedData, () => {
      selectedItems.value = selectedItems.value.filter((item) =>
        displayedData.value.includes(item)
      );
    });

    const toggleSelected = (item: TableDataItem) => {
      if (selectedItems.value.includes(item)) {
        selectedItems.value = selectedItems.value.filter(
          (selected) => selected !== item
        );
      } else {
        selectedItems.value = [...selectedItems.value, item];
      }
    };

    const toggleAllSelected = () => {
      if (allSelected.value) {
        selectedItems.value = [];
      } else {
        selectedItems.value = [...props.data];
      }
    };

    const sortTable = (value: any) => {
      emit("sortTable", value);
      console.log("Sort table:", value);
    };

    const toggleSorted = (column: TableColumn) => column.field;

    return {
      selectedItems,
      allSelected,
      toggleSelected,
      toggleAllSelected,
      sortTable,
      toggleSorted,
    };
  },
};
</script>

<style scoped>
table {
  width: 100%;
}

thead th {
  border-bottom: 1px solid #ddd;
  text-align: left;
  /*padding: 10px;*/
  background-color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  color: #8e98a4;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #1d324a;
}

.cursor-p {
  cursor: pointer;
}
</style>
