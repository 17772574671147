<template>
  <button :class="classNames" :disabled="disabled" @click="handleClick">
    {{ text }}
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "AtomButton",
  props: {
    text: {
      type: String,
      default: "Default",
    },
    className: {
      type: String,
    },
    type: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const classNames = computed(() => {
      const classes = ["btn"];
      if (props.type) {
        classes.push(props.type);
      }
      if (props.className) {
        classes.push(props.className);
      }
      return classes.join(" ");
    });

    function handleClick() {
      // Emit an event or perform any specific action on button click
      emit("clicked");
    }

    return {
      classNames,
      handleClick,
    };
  },
});
</script>

<style scoped>
.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #79c300;
  border-radius: 4px;
  order: 0;
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 11px;
  border: 3px solid #79c300;
}

.btn:hover {
  background: #a5dc31;
  border-color: #a5dc31;
}

.btn:active {
  background: #66a300;
  border-color: #66a300;
}
.btn:disabled {
  background: #dfe2e6;
  border-color: #dfe2e6;
  cursor: not-allowed;
}
.btn.type1 {
  background-color: #ffffff;

  color: #79c300;
}
.btn.type1:hover {
  border-color: #a5dc31;
  color: #a5dc31;
}

.btn.type1:active {
  border-color: #66a300;
  color: #66a300;
}
.btn.type1:disabled {
  border-color: #dfe2e6;
  color: #dfe2e6;
  cursor: not-allowed;
}

.ico {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 29.17%;
  bottom: 29.17%;
  border: 1.75px solid #79c300;
}
</style>
