<template>
  <label class="radio-container">
    <input
      type="radio"
      :name="name"
      :value="value"
      v-model="selectedValue"
      :checked="checked"
      @change="handleRadio"
    />
    <span class="checkmark"></span>
    <Text class="m-0" :message="label"></Text>
  </label>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Text from "@/components/Atoms/AtomText.vue";

export default defineComponent({
  name: "AtomRadio",
  components: {
    Text,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: false,
    },
    label: {
      type: String,
      default: "",
    },
    selected: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const selectedValue = ref(props.selected);

    const handleRadio = (event: Event) => {
      const target = event.target as HTMLInputElement;
      selectedValue.value = target.value;
      emit("handleRadio", target.value);
    };

    watch(
      () => props.selected,
      (newVal) => {
        selectedValue.value = newVal;
      }
    );

    return {
      selectedValue,
      handleRadio,
    };
  },
});
</script>

<style scoped>
.radio-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  user-select: none;
}
.radio-container input {
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #c2c2c2;
  margin-right: 10px;
}
.radio-container:hover input ~ .checkmark {
  border-color: #666;
}
.radio-container input:checked ~ .checkmark {
  border-color: #79c300;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
.radio-container .checkmark[data-v-a77f7806]:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 70%;
  background: #79c300;
}
</style>
