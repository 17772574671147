import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import loginRoute from "./login";
import homeRoute from "./home";
import dashboardRoute from "./dashboard";
import listAbonnementRoute from "./listAbonnement";
import configRoute from "./config";
import inscriptionRoute from "./inscription";
import forgetPassRoute from "./forgetPass";
import resetPassRoute from "./resetPass";
import { useAuthStore } from "@/Stores/auth";
import plansRoute from "./plans";

const routes: Array<RouteRecordRaw> = [
  loginRoute,
  homeRoute,
  inscriptionRoute,
  forgetPassRoute,
  resetPassRoute,
  plansRoute,
  listAbonnementRoute,
  configRoute,
  dashboardRoute
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to, from, next) => {
  const authStore = useAuthStore();
  if (to.matched.some((m) => m.meta.requiresAuth) && !authStore.token) {
    next({
      name: "login",
    });
  } else if (
    to.matched.some((m) => m.meta.authorize) &&
    !(to as any).meta.authorize.includes(authStore.permissions)
  ) {
    next({
      name: "/",
    });
  } else {
    next();
  }
});

export default router;
