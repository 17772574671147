/*interface AtomButtonProps {
    className?: string
    text: string
}*/
interface Rappel {
    titre: string;
    typeAlerte: string;
    ressource: {
      name: string;
    };
  }
export interface MoleculNavbarItemsProp {
    text: string
    link: string
}

export enum State {
    InProgress = 'secondry',
    completed = 'success',
    pending = 'pending',
    approved = 'warning',
    rejected = 'error',
}

export enum InputState {
    default = 'default',
    success = 'success',
    error = 'error',
}

export enum ETypesInput {
    password = "password",
    text = "text"
}

// AtomAvatar
export enum Size {
    small = "16",
    medium = "32",
    large = "48",
    xlarge = "64",
    xxlarge = "80",
    customTable = "24"
}

export interface MyComponentType {
    allSelected: boolean;
    selectedItems: Array<object>;

  }

