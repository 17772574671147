<template>
  <div v-if="hasSrc" :class="avatarClassNames">
    <img
      v-if="hasSrc"
      :src="src"
      :alt="alt"
      :title="title"
      class="avatar-img"
    />
    <span v-else :class="textClassNames">{{ fullName }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { Size } from "@/Interfaces";

export default defineComponent({
  name: "AvatarAtom",
  props: {
    src: {
      type: String,
      required: false,
      default: "",
    },
    alt: {
      type: String,
      required: false,
    },
    title: String,
    size: {
      type: String as PropType<Size>,
      required: true,
    },
    fullName: {
      type: String,
      required: false,
      default: "HM",
    },
  },
  setup(props:any) {
    const hasSrc = computed(() => props.src !== "");

    const avatarClassNames = computed(() => {
      const classNames = ["avatar", hasSrc.value ? "" : "with-text"];

      switch (props.size) {
        case Size.small:
          classNames.push("ds-wp-16 ds-hp-16");
          break;
        case Size.medium:
          classNames.push("ds-wp-24 ds-hp-24");
          break;
        case Size.large:
          classNames.push("ds-wp-32 ds-hp-32");
          break;
        case Size.xlarge:
          classNames.push("ds-wp-48 ds-hp-48");
          break;
        case Size.customTable:
          classNames.push("ds-wp-64 ds-hp-64");
          break;
        default:
          classNames.push("ds-wp-80 ds-hp-80");
          break;
      }
      return classNames.join(" ");
    });

    const textClassNames = computed(() => {
      const classNames = ["avatar-text"];

      switch (props.size) {
        case Size.small:
          classNames.push("ds-text-size-6 ds-text-line-6");
          break;
        case Size.medium:
          classNames.push("ds-text-size-9 ds-text-line-9");
          break;
        case Size.large:
          classNames.push("ds-text-size-15 ds-text-line-15");
          break;
        case Size.xlarge:
          classNames.push("ds-text-size-19 ds-text-line-19");
          break;
        case Size.customTable:
          classNames.push("ds-text-size-9 ds-text-line-9");
          break;
        default:
          classNames.push("ds-text-size-29 ds-text-line-29");
          break;
      }
      return classNames.join(" ");
    });

    return {
      hasSrc,
      avatarClassNames,
      textClassNames,
    };
  },
});
</script>

<style>
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.avatar.with-text {
  font-family: "Lato";
  font-style: normal;
  background-color: #eee;
}

.avatar-img {
  width: 100%;
  height: 100%;
  font-weight: 700;
  object-fit: cover;
}

.avatar-text {
  object-fit: cover;
  color: #79c300;
}
</style>
