import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-200abe84"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "navbar-list"
}
const _hoisted_2 = { class: "ds-flex ds-align-center navbar-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'ds-flex',
      'container-navbar',
      'py-6',
      'px-16',
      'ds-align-center',
      _ctx.className,
    ])
  }, [
    (_ctx.items)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item.text,
              class: "txt btn-text"
            }, [
              _createVNode(_component_router_link, {
                to: item.link,
                class: "navbar-item"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.text), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, {
        icon: "fa-regular fa-bell",
        style: { cursor: 'pointer' },
        onClick: _ctx.handleBellIconClick
      }, null, 8, ["onClick"]),
      _createElementVNode("li", null, [
        _createVNode(_component_Button, {
          text: "Déconnexion",
          className: "ml-20",
          onClicked: _ctx.logout
        }, null, 8, ["onClicked"])
      ])
    ])
  ], 2))
}