import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Text, {
      type: "btn-text",
      className: _ctx.labelClassNames,
      message: _ctx.labelText,
      isRequired: _ctx.isRequired
    }, null, 8, ["className", "message", "isRequired"]),
    _createVNode(_component_Input, {
      state: _ctx.state,
      className: _ctx.inputClassNames,
      isPassword: _ctx.isPassword,
      placeholder: _ctx.placeholder,
      type: _ctx.renderIsPassword,
      onHandleInput: _ctx.handleInput,
      value: _ctx.value,
      disabled: _ctx.disabled
    }, null, 8, ["state", "className", "isPassword", "placeholder", "type", "onHandleInput", "value", "disabled"]),
    (_ctx.state === _ctx.InputState.success || _ctx.state === _ctx.InputState.error)
      ? (_openBlock(), _createBlock(_component_Text, {
          key: 0,
          type: "btn-text",
          className: _ctx.textClassNames,
          message: _ctx.inputText,
          style: {"margin-bottom":"0px"}
        }, null, 8, ["className", "message"]))
      : _createCommentVNode("", true)
  ]))
}