import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e28d6f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "required"
}
const _hoisted_2 = {
  key: 1,
  style: {"color":"red"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isP)
    ? (_openBlock(), _createElementBlock("p", {
        key: 0,
        class: _normalizeClass(_ctx.computedClassNames)
      }, [
        _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
        (_ctx.isRequired)
          ? (_openBlock(), _createElementBlock("label", _hoisted_1, "*"))
          : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.message), 1))
}