import { defineStore } from "pinia";
import { toast as showToast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css';

interface ToastState {
  toastType: string | null;
  toastMessage: string;
}

export const useToastStore = defineStore("toast", {
  state: (): ToastState => ({
    toastType: null,
    toastMessage: "",
  }),
  actions: {
    renderToast({
      toastType,
      toastMessage,
    }: {
      toastType: string;
      toastMessage: string;
    }) {
      this.toastType = toastType;
      this.toastMessage = toastMessage;

      if (toastType === "success") {
        showToast.success(toastMessage, {
          autoClose: 3000,
          position: "top-center",
        });
      } else if (toastType === "error") {
        showToast.error(toastMessage, {
          autoClose: 3000,
          position: "top-center",
        });
      }
      else if (toastType === "warning") {
        showToast.warning(toastMessage, {
          autoClose: 3000,
          position: "top-center",
        });
      }
    },
  },
});
