import { RouteRecordRaw } from "vue-router";
import InscriptionView from "../views/InscriptionView.vue";

const inscriptionRoute: RouteRecordRaw = {
  path: "/inscription",
  name: "inscription",
  component: InscriptionView,
  meta: {
    // requiresAuth: true,
  },
};

export default inscriptionRoute;
