import { InputState} from '@/Interfaces';


export function pwRules(value: string ){
  const obj = {
    stateInput : InputState.default,
    msgInput  : ''
  }
  if (value.length === 0) {
    obj.stateInput = InputState.error
    obj.msgInput = "Le mot de passe est obligatoire!"
  }
  else if (value.length < 8) {
    obj.stateInput  = InputState.error
    obj.msgInput = "Le mot de passe doit contenir 8 caractères!"

  }
  else if (value.length >= 8) {
    obj.msgInput = ""
    obj.stateInput= InputState.success
  }
   return obj
}

export function confirmPwRules(value1:string ,value2: string ){
  const obj = {
    stateInput : InputState.default,
    msgInput  : ''
  }
  if (value1.length === 0) {
    obj.stateInput = InputState.error
    obj.msgInput = "Le mot de passe est obligatoire!"
  }else if (value2 !== value1) {
    obj.stateInput = InputState.error
    obj.msgInput = "Les mots de passe doivent être identiques !"
  }else{
    obj.stateInput = InputState.success
    obj.msgInput = ""
  }
 
   return obj
}

 export function emailRules(value: string){
  const regex = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
  const obj = {
    userEtat : InputState.default,
    userMsg  : ''
  }
  
  if (value.length === 0) {
    obj.userEtat = InputState.error
    obj.userMsg = "L'email est obligatoire!"
  }
  else if(regex.test(value)){
    obj.userMsg = ""
    obj.userEtat = InputState.success
  }
  else {
    obj.userMsg = "L'adresse email est invalide!"
    obj.userEtat = InputState.error
  }
  
  return obj
}

export function numberRules(value: any){
  const numberRegex = /^\d+$/;
  const obj = {
    stateInput : InputState.default,
    msgInput  : ''
  }

  if (value.length === 0) {
    obj.stateInput = InputState.error
    obj.msgInput = "Le champ est obligatoire!"
  }
  else if(numberRegex.test(value)){
    obj.stateInput = InputState.success
    obj.msgInput = ""  
  }
  else {
    obj.stateInput = InputState.error
    obj.msgInput = "Le champ doit comporter que des nombres!"
  }

  return obj
}

export function telRules(value: any){
  const regex = /^[0-9]{8}$/;
  const obj = {
    stateInput : InputState.default,
    msgInput  : ''
  }

  if (value.length === 0) {
    obj.stateInput = InputState.error
    obj.msgInput = "Le champ est obligatoire!"
  }
  else if(regex.test(value)){
    obj.stateInput = InputState.success
    obj.msgInput = ""  
  }
  else{
    obj.stateInput = InputState.error
    obj.msgInput = "Le numéro n'est pas valide!"
  }
  return obj
}