<template>
  <div class="absolute ds-inset-0 ds-overflow-y-auto ds-bg-black">
    <div class="ds-flex ds-align-start ds-justify-center mt-24 text-center">
      <div
        class="ds-bg-white ds-text-black ds-rounded-lg text-center ds-shadow-xl p-6 ds-wp-350"
        role="dialog"
        aria-model="true"
      >
        <Text className="ds-text-weight-800" :message="title"></Text>
        <Text :message="content"></Text>
        <div class="ds-flex ds-justify-around py-4 ds-text-white dx-60  btn-modal">
          <Button type="type1" text="Annuler" @clicked="closeModalConfirm"></Button>
          <Button :text="submitMessage" @clicked="confirmAction"></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Molecules/MoleculButton.vue";
import Text from "@/components/Atoms/AtomText.vue";

export default {
  components: {
    Button,
    Text,
  },
  props: {
    title: {
      type: String,
      default: "Label",
    },
    content: {
      type: String,
      default: "content",
    },
    submitMessage: {
      type: String,
      default: "Supprimer",
    },
  },
  setup(props, { emit }) {
    const closeModalConfirm = () => {
      emit("close");
    };

    const confirmAction = () => {
      emit("confirm");
    };

    return {
      closeModalConfirm,
      confirmAction,
    };
  },
};
</script>

<style scoped>
.zindex {
  z-index: 9999;
}
.p-6 {
  padding: 10px 30px 22px;
}
</style>
