import { RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const homeRoute: RouteRecordRaw = {
  path: "/",
  name: "home",
  component: HomeView,
  meta: {
    // requiresAuth: true,
  },
};

export default homeRoute;
