import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/styles/main.css";
import "./assets/styles/main.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import ToastifyPlugin from "vue3-toastify";
import { createPinia } from "pinia";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import 'maz-ui/css/main.css';
library.add(fas);
library.add(far);

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(createPinia());
app.use(ToastifyPlugin);
app.component('MazPhoneNumberInput', MazPhoneNumberInput)
app.mount("#app");
