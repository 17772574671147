<template>
  <div>
    <Text
      type="btn-text"
      :className="labelClassNames"
      :message="labelText"
      :isRequired="isRequired"
    ></Text>
    <Input
      :state="state"
      :className="inputClassNames"
      :isPassword="isPassword"
      :placeholder="placeholder"
      :type="renderIsPassword"
      @handleInput="handleInput"
      :value="value"
      :disabled="disabled"
    >
      <!-- <font-awesome-icon icon="fa-regular fa-circle-check" /> -->
    </Input>
    <Text
      v-if="state === InputState.success || state === InputState.error"
      type="btn-text"
      :className="textClassNames"
      :message="inputText"
      style="margin-bottom: 0px"
    ></Text>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import Input from "@/components/Atoms/AtomInput.vue";
import Text from "@/components/Atoms/AtomText.vue";
import { ETypesInput, InputState } from "@/Interfaces";

export default defineComponent({
  name: "MoleculInput",
  components: {
    Input,
    Text,
  },
  props: {
    labelText: {
      type: String,
      default: "Label",
    },
    inputText: {
      type: String,
      default: "Helper Text",
    },
    labelClassName: {
      type: String,
    },
    textClassName: {
      type: String,
    },
    inputClassName: {
      type: String,
    },
    type: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "placeholder",
    },
    state: {
      type: String as PropType<InputState>,
    },
    withButton: {
      type: Boolean,
      default: false,
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const labelClassNames = computed(() => {
      const classNames = ["mb-8"];
      if (props.labelClassName) {
        classNames.push(props.labelClassName);
      }
      return classNames.join(" ");
    });

    const textClassNames = computed(() => {
      const classNames = ["mt-5"];
      if (props.textClassName) {
        classNames.push(props.textClassName);
      }
      if (props.state === InputState.error) {
        classNames.push("input-txt-error");
      }
      if (props.state === InputState.success) {
        classNames.push("input-txt-success");
      }
      return classNames.join(" ");
    });

    const inputClassNames = computed(() => {
      const classNames = ["large"];
      if (props.inputClassName) {
        classNames.push(props.inputClassName);
      }
      return classNames.join(" ");
    });

    const renderIsPassword = computed(() => {
      const password = ETypesInput.password;
      const text = ETypesInput.text;
      return props.isPassword ? password : text;
    });

    const handleInput = (value: any) => {
      emit("handleInput", value);
    };

    return {
      InputState,
      labelClassNames,
      textClassNames,
      inputClassNames,
      renderIsPassword,
      handleInput,
    };
  },
});
</script>

<style scoped>
.input-ds .default {
  border: 1px solid #c9ced4;
}

.input-ds .success {
  border: 1px solid #16a34a;
}

.input-ds .error {
  border: 1px solid #fca5a5;
}

.input-txt-error {
  color: #dc2626;
}

.input-txt-success {
  color: #16a34a;
}
</style>
