import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b207e874"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Text, {
      type: "btn-text",
      className: _ctx.labelClassNames,
      message: _ctx.labelText,
      isRequired: _ctx.isRequired
    }, null, 8, ["className", "message", "isRequired"]),
    _withDirectives(_createElementVNode("select", {
      class: "dropdown",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleSelect && _ctx.handleSelect(...args))),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedIndex) = $event))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-down" }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: index
        }, _toDisplayString(item.label), 9, _hoisted_1))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.selectedIndex]
    ])
  ]))
}