<template>
  <div>
    <Text
      type="btn-text"
      :className="labelClassNames"
      :message="labelText"
      :isRequired="isRequired"
    ></Text>
    <select class="dropdown" @change="handleSelect" v-model="selectedIndex">
      <font-awesome-icon icon="fa-solid fa-chevron-down" />
      <option v-for="(item, index) in items" :key="index" :value="index">
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Text from "@/components/Atoms/AtomText.vue";


export default defineComponent({
  name: "AtomSelect",
  components: {
    Text,
  },
  props: {
    items: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    value: {
      type: Number,
      required: false,
      default: -1,
    },
    labelText: {
      type: String,
      default: "",
    },
    inputText: {
      type: String,
      default: "Helper Text",
    },
    labelClassNames: {
      type: String,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedIndex = ref(props.value);

    const handleSelect = (event: Event) => {
      const target = event.target as HTMLSelectElement;
      selectedIndex.value = Number(target.value);
      const selectedItem = props.items[selectedIndex.value]?.value;
      emit("update:value", selectedIndex.value);
      emit("handleSelect", selectedItem);
    };

    watch(
      () => props.value,
      (newValue) => {
        selectedIndex.value = newValue;
      }
    );

    return {
      selectedIndex,
      handleSelect,
    };
  },
});
</script>

<style scoped>
.dropdown {
  cursor: pointer;
  padding: 10px;
  background-color: white;
  border: 1px solid #c9ced4;
  width: 100%;
  height: 40px;
  outline: none;
  border-radius: 8px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #8e98a4;
}
</style>
